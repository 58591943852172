/* Classe per posizionare gli elementi a destra della pagina */
.right {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: flex;
  align-items: center;
}

/* Aggiungi margine per spaziare gli elementi sulla destra */
.right > * {
  margin-right: 10%; /* Puoi regolare questo valore per ottenere lo spazio desiderato */
}

iframe {
  border: none;
  width: 100%;
  height: 100vh;
}

/* Media query per la disposizione responsive */
@media screen and (max-width: 768px) {
  .right {
    position: relative;
    top: auto;
    right: auto;
    height: auto;
    flex-direction: column;
    align-items: flex-end;
    padding: 10px; /* Spazio di padding aggiunto per migliorare la leggibilità */
  }

  .right > * {
    margin-right: 0;
    margin-bottom: 10px; /* Spazio di margin per separare gli elementi nella disposizione a colonna */
  }
}

.home-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 238px;
}

.home-main h1 {
  margin-top: 40vh;
  font-weight: bold;
  margin-bottom: 8px;
  font-size: 48px;
}

.home-main p {
  margin-top: 0px;
  opacity: 0.6;
}
