.menu-item-container {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  padding: 0px 0px 0px 8px;
  position: relative;
  width: 237px;
  align-items: center;
  cursor: pointer;
}

.menu-item-container .menu-item-content {
  align-items: center;
  border-radius: 16px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  padding: 12px;
  position: relative;
}

.menu-item-container .menu-item-text {
  color: var(--dark-grey);
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: nowrap;
  width: 154px;
}

.menu-item-container .div {
  align-items: center;
  background-color: var(--blue);
  border-radius: 8px;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 12px;
  padding: 12px;
  padding-left: 18px;
  position: relative;
}

.menu-item-container .text-wrapper {
  color: var(--white);
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: 154px;
  margin: 0;
}

.menu-item-container .icon-interfaces-home {
  margin-top: 2px;
}

.menu-item-container .selection-border {
  background-color: var(--blue);
  border-radius: 40px;
  height: 38px;
  position: relative;
  width: 7px;
}
