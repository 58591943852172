/* Modifiche per la classe "sign-up-interno" */
.sign-up-interno {
  align-items: center;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* padding-bottom: 25%; */
  /* margin-bottom: 45%; */
  position: relative;
}

body {
  margin: 0;
  padding: 0;
  background-color: #f6fdff; /* Sostituisci con il colore di sfondo desiderato */
}

/* Modifiche per la classe "logo" (nessun cambiamento) */
.sign-up-interno .logo {
  height: 100px;
  min-width: 200px;
  object-fit: cover;
  position: relative;
}

/* Modifiche per la classe "sign-up-screen" */
.sign-up-interno .sign-up-screen {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--grey);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow: hidden;
  padding: 36px;
  position: relative;
  width: 480px;
}

/* Modifiche per la classe "typeforms-buttons" (nessun cambiamento) */
.sign-up-interno .typeforms-buttons {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

/* Modifiche per la classe "title-container" (nessun cambiamento) */
.sign-up-interno .title-container {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
  position: relative;
}

/* Modifiche per la classe "title" (nessun cambiamento) */
.sign-up-interno .title {
  color: var(--black);
  flex: 1;
  font-family: "Nunito Sans", sans-serif !important;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28.8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  font-weight: bold !important;
}

/* Modifiche per la classe "div" (nessun cambiamento) */
.sign-up-interno .div {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

/* Modifiche per la classe "type-form-instance" (nessun cambiamento) */
.sign-up-interno .type-form-instance {
  align-self: stretch !important;
  width: unset !important;
}

/* Modifiche per la classe "design-component-instance-node" (nessun cambiamento) */
.sign-up-interno .design-component-instance-node {
  min-width: 24px !important;
  width: unset !important;
}

/* Modifiche per la classe "other-typeform" (nessun cambiamento) */
.sign-up-interno .other-typeform {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
}

/* Modifiche per la classe "ricordami-check" (nessun cambiamento) */
.sign-up-interno .ricordami-check {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  position: relative;
  width: fit-content;
}

/* Modifiche per la classe "text-wrapper" (nessun cambiamento) */
.sign-up-interno .text-wrapper {
  color: var(--orange);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  width: fit-content;
}

/* Modifiche per la classe "text-wrapper-2" (nessun cambiamento) */
.sign-up-interno .text-wrapper-2 {
  color: var(--orange);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  width: fit-content;
}

/* Modifiche per la classe "buttons" (nessun cambiamento) */
.sign-up-interno .buttons {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

/* Modifiche per la classe "button-instance" (nessun cambiamento) */
.sign-up-interno .button-instance {
  align-self: stretch !important;
  justify-content: center !important;
  width: unset !important;
}

/* Responsive Styles */

/* Modifiche per schermi di larghezza massima 768px */
@media (max-width: 768px) {
  .sign-up-interno {
    padding: 20px;
  }

  .sign-up-interno .sign-up-screen {
    width: 100%;
  }
}

/* Modifiche per schermi di larghezza massima 480px */
@media (max-width: 480px) {
  .sign-up-interno .sign-up-screen {
    padding: 20px;
    /* Aumenta la lunghezza del componente in verticale */
    height: 100%;
  }
}

.title-subtitle-container {
  -webkit-font-smoothing: antialiased;
  font-family: "Nunito Sans", sans-serif;
  box-sizing: border-box;
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
}

.subtitle-tel {
  -webkit-font-smoothing: antialiased;
  box-sizing: border-box;
  width: 100%;
  color: var(--black);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: static;
  text-align: center;
  margin: 0;
}

.subtitle {
  padding-inline: 36px;
  text-align: center;
  white-space: normal;
  margin-bottom: 0px;
  width: -webkit-fill-available;
}

.grid-welcome {
  gap: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.grid-welcome .title {
  margin-bottom: 0;
  font-size: 1.5rem;
}

.grid-welcome img {
  height: auto;
  width: 250px;
  object-fit: cover;
}

.grid-welcome .subtitle {
  text-wrap: wrap;
  margin-bottom: 0px;
}

.selection-element-horrizonatal {
  width: 100%;
}

.dropdown-instance {
  width: 100%;
}
