.icon-interfaces-user {
  height: 24px;
  position: relative;
  width: 24px;
}

.icon-interfaces-user:focus-within {
  height: 60px;
}
.icon-interfaces-user .combined-shape {
  height: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 16px;
}
