/* style.css */

.date-form {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--grey);
  border-radius: 8px;
  display: flex;
  gap: 12px;
  height: 60px;
  padding: 8px 20px 8px 16px;
  position: relative;
}

.campo-input::placeholder {
  color: var(--dark-grey); /* colore del placeholder */
}

.date-form .divider:focus {
  background-color: var(--black);
}

.date-form .campo-input:focus-within {
  color: var(--black);
}

.date-form:focus-within {
  /* I tuoi stili qui. Esempio: */
  border: 1px solid var(--black);
  background-color: white;
}

.date-form .icon :focus-within {
  font-weight: 700;
}

.date-form .icon {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  position: relative;
  width: fit-content;
}

.date-form .divider {
  background-color: var(--grey);
  height: 20px;
  min-width: 1px;
  position: relative;
}

/* Hide the default arrow and calendar of the date input */
.date-form input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
}

.date-form input[type="date"] {
  color: var(--black);
  font-family: "Inter", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  margin-top: -1px;
  position: relative;
  /* Make the campo-input appear as an input field */
  border: none;
  outline: none;
  background-color: transparent;
  /* Extend to fill the available horizontal space */
  width: 100%;
  box-sizing: border-box;
}

.date-form .campo-input {
  align-self: stretch;
  color: var(--dark-grey);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  /* Make the campo-input appear as an input field */
  border: none;
  outline: none;
  background-color: transparent;
}
