.type-form {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--grey);
  border-radius: 8px;
  display: flex;
  gap: 12px;
  height: 60px;
  padding: 8px 20px 8px 16px;
  position: relative;
  width: 95%;
}

.campo-input::placeholder {
  color: var(--dark-grey); /* colore del placeholder */
}

.type-form:focus-within {
  /* I tuoi stili qui. Esempio: */
  border: 1px solid var(--black);
  background-color: white;
}

.type-form .icon {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  position: relative;
  width: fit-content;
}

.type-form .icon :focus-within {
  font-weight: 700;
}

.type-form .divider {
  background-color: var(--grey);
  height: 20px;
  min-width: 1px;
  position: relative;
}

.type-form .divider:focus {
  background-color: var(--black);
}

.type-form .campo-input:focus-within {
  color: var(--black);
}

.type-form .campo-input {
  align-self: stretch;
  font-family: "Inter", Helvetica;
  font-weight: 400;
  letter-spacing: 0;
  position: relative;

  color: var(--black);
  /* Make the campo-input appear as an input field */
  border: none;
  outline: none;
  background-color: transparent;
}

.type-form .icon-interfaces-user-instance {
  min-width: 24px !important;
  width: unset !important;
}

.type-form .icon-interfaces-show-password {
  width: 20px !important;
  height: 20px !important;
  cursor: pointer;
}

input[type="number"] {
  appearance: none;
  -moz-appearance: textfield;
  font-size: 1rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input {
  width: 100%;
  max-width: -webkit-fill-available;
}
