.icon-interfaces-lock {
  height: 24px;
  position: relative;
  width: 24px;
}

.icon-interfaces-lock .shape {
  height: 18px;
  left: 5px;
  position: absolute;
  top: 3px;
  width: 14px;
}
