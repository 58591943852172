/* Stili di base per .checkbox */
.checkbox {
  height: 24px;
  position: relative;
  width: 24px;
}

.checkbox .img {
  height: 18px;
  left: 3px;
  position: absolute;
  top: 3px;
  width: 18px;
}

/* Stili per .label */
.label {
  background-color: transparent;
  display: flex;
  align-items: center; /* Per allineare verticalmente il contenuto */
}

.label .text {
  color: var(--black);
  font-family: "Inter-Regular", Helvetica;
  font-size: 16px;
  font-weight: 400;
  margin-left: 10px; /* Aggiungiamo un margine a sinistra per separare .img e .paragraph */
  /* I tuoi stili per .paragraph, se necessari */
}
