.banner {
  align-items: flex-start;
  background-color: var(--white);
  display: flex;
  width: 100%;
  position: relative;
}

.banner .div {
  align-items: center;
  flex: 1;
  background-color: #eec8c6;
  border-radius: 8px;
  display: flex;
  position: relative; /* Aggiungi position: relative */
}

.container-error-message {
  display: flex;
  flex-direction: row !important;
  align-items: center;
  padding-inline: 24px;
  gap: 8px;
}

.banner .text-frame {
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  gap: 4px;
  padding-block: 16px;
  position: relative;
}

.banner .headline-text {
  align-self: stretch;
  color: var(--red);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
}

.banner .paragraph-text {
  align-self: stretch;
  color: var(--red);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  position: relative;
  white-space: wrap;
}
