.button {
  align-items: flex-start;
  border-radius: 8px;
  display: flex;
  gap: 10px;
  position: relative;
  width: fit-content;
  font-weight: 700;
}

.button .button-primary {
  font-family: "Inter", Helvetica;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
}

.button .button-secondary {
  font-family: "Inter", Helvetica;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
  width: fit-content;
  
  color: var(--black) !important;
}

.button.secondary {
  border: 0px solid;
  padding: 16px 24px;
  transition: color 0.3s, border-color 0.3s; /* Add transition for color and border-color */
  background-color: var(--white);
  border-color: var(--orange);
  color: var(--black) !important;
}

.button.primary {
  background-color: var(--orange);
  color: var(--white);
  padding: 16px 24px;
  transition: color 0.3s, background-color 0.3s; /* Add transition for color and background-color */
  border: 0px;
}



.button.secondary:hover {
  cursor: pointer;
}

.button.primary:hover {
  background-color:  var(--darkOrange) !important;
  /* color: var(--orange) !important; /* Change label color to black on hover */
  cursor: pointer;
  margin: 1px solid;
}

