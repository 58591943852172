/* styles.css */
.text-field {
  display: flex; /* Usa Flexbox per gestire il layout */
  flex-direction: column; /* Imposta la direzione del layout come colonna */
  height: 100%; /* Assicurati che il contenitore abbia un'altezza definita per espandersi correttamente */
}

textarea:focus {
  outline: none; /* Remove outline when the textarea is focused */
}

textarea {
  resize: none; /* Disable textarea resizing (optional) */
  flex: 1;
  align-items: center;
  background-color: var(--white);
  border: 2px solid #8b8b8b;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 60px;
  padding: 8px 20px 8px 16px;
  position: relative;
}

.text-field .div {
  align-items: center;
  align-self: stretch;
  background-color: var(--white);
  border-radius: 8px;
  display: flex;
  gap: 12px;
  height: 60px;
  overflow: hidden;
  padding: 8px 20px 8px 16px;
  position: relative;
  width: 100%;
}

.text-field .icon {
  align-items: flex-start;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 8px;
  position: relative;
}

.text-field .icon-interfaces-user {
  height: 24px;
  position: relative;
  width: 24px;
}

.text-field .combined-shape {
  height: 16px;
  left: 4px;
  position: absolute;
  top: 4px;
  width: 16px;
}

.text-field .divider {
  height: 20px;
  position: relative;
  width: 1px;
}

.text-field .campo-text-area {
  align-self: stretch;
  color: var(--dark-grey);
  flex: 1;
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
}
