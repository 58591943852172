/*Subtitle*/
.subtitle {
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  color: var(--black);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  margin-top: -8%;
  left: 0;
  letter-spacing: 0;
  line-height: 24px;
  position: static;
  text-align: center;
  white-space: nowrap;
  margin-bottom: 0px;
}

/* Modifiche per la classe "onboarding-interno" */
.onboarding-interno {
  align-items: center;
  background-color: #f6fdff;
  border: none;
  display: flex;
  flex-direction: column;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
  /* padding-bottom: 25%; */
  position: relative;
  /* height: calc(100vh - 4rem); */
}

/* Modifiche per la classe "logo" (nessun cambiamento) */
.onboarding-interno .logo {
  height: 100px;
  min-width: 200px;
  object-fit: cover;
  position: relative;
}

/* Modifiche per la classe "onboarding-screen" */
.onboarding-interno .onboarding-screen {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--grey);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  /* overflow: hidden; */
  padding: 36px;
  position: relative;
  width: 480px;
  display: flex;
  flex-direction: column;
}

/* Modifiche per la classe "typeforms-buttons" (nessun cambiamento) */
.onboarding-interno .typeforms-buttons {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  overflow: auto;
  flex-wrap: nowrap;
}

/* Modifiche per la classe "title-container" (nessun cambiamento) */
.onboarding-interno .title-container {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
  position: relative;
  white-space: wrap;
}

/* Modifiche per la classe "title" (nessun cambiamento) */
.onboarding-interno .title {
  color: var(--black);
  flex: 1;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28.8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: wrap;
}

/* Modifiche per la classe "div" (nessun cambiamento) */
.onboarding-interno .div {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.onboarding-interno .container-error-message {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0px;
  padding-right: 16px;
  padding-left: 20px;
}

/* Modifiche per la classe "type-form-instance" (nessun cambiamento) */
.onboarding-interno .type-form-instance {
  align-self: stretch !important;
  width: unset !important;
}

/* Modifiche per la classe "design-component-instance-node" (nessun cambiamento) */
.onboarding-interno .design-component-instance-node {
  min-width: 24px !important;
  width: unset !important;
}

/* Modifiche per la classe "other-typeform" (nessun cambiamento) */
.onboarding-interno .other-typeform {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
}

/* Modifiche per la classe "ricordami-check" (nessun cambiamento) */
.onboarding-interno .ricordami-check {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  position: relative;
  width: fit-content;
}

/* Modifiche per la classe "text-wrapper" (nessun cambiamento) */
.onboarding-interno .text-wrapper {
  color: var(--black);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

/* Modifiche per la classe "text-wrapper-2" (nessun cambiamento) */
.onboarding-interno .text-wrapper-2 {
  color: var(--black);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

/* Modifiche per la classe "buttons" (nessun cambiamento) */
.onboarding-interno .buttons {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

/* Modifiche per la classe "button-instance" (nessun cambiamento) */
.onboarding-interno .button-instance {
  align-self: stretch !important;
  justify-content: center !important;
  width: unset;
}

.selection-element-horrizonatal {
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px; /* Modifica questi valori per regolare la spaziatura desiderata in verticale e orizzontale */
}

/* Opzionale: per distribuire spaziatura uniformemente tra gli elementi */
.selection-element-horrizonatal > * {
  flex: 1;
}

/* Responsive Styles */

/* Modifiche per schermi di larghezza massima 768px */
@media (max-width: 768px) {
  .onboarding-interno {
    padding: 20px;
  }

  .onboarding-interno .onboarding-screen {
    width: 100%;
  }
}

/* Modifiche per schermi di larghezza massima 480px */
@media (max-width: 480px) {
  .onboarding-interno .onboarding-screen {
    padding: 20px;
    /* Aumenta la lunghezza del componente in verticale */
    height: 100%;
  }
}

.wrapper-select-prefix {
  position: relative;
}

.select-number-prefix {
  width: 40px;
  outline: none;
  border: none;
  font-family: inherit;
  cursor: pointer;
  font-weight: bold;
  color: var(--dark-grey);
  appearance: none;
  opacity: 0;
  position: absolute;
  inset: 0;
}

.primary-buttons-wrapper {
  display: flex;
  grid-gap: 8px;
  width: 100%;

  .add-btn {
    background-color: transparent;
    border: 1.5px solid var(--orange);
    color: var(--orange);

    &:hover {
      color: white;
    }
  }

  .next-btn {
    width: 100%;
  }
}

.button.primary {
  width: 100%;
  justify-content: center;
}

.button-disabled {
  filter: grayscale(1);
  pointer-events: none;
  width: 100%;
  justify-content: center;
}

.text-limit-wrapper {
  margin-left: auto;
  font-size: 0.8rem;
  color: #cbcbcb;
  font-weight: 700;
}

.title-custom {
  margin-bottom: 8px;
}

.subtitle-custom {
  font-size: 0.9rem;
  color: #cbcbcb;
  font-weight: 500;
  margin: 0;
  text-align: center;
  margin-top: 8px;
  margin-bottom: 16px;
}

.accordions {
  width: fill-available;
  width: -webkit-fill-available;
  width: -moz-available;
  width: -ms-fill-available;
}

.accordion-container .accordion-button .chevron.open {
  transform: rotate(135deg) !important;
}

.buttons-experiences {
  margin-top: 48px;
}

.button-highlight {
  background-color: yellow;
}

.wrapper-date-title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 8px;
}

.wrapper-date-title__text {
  font-size: 0.9rem;
  opacity: 0.5;
}

.wrapper-title-subtitle {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  grid-gap: 8px;
}

.wrapper-title-subtitle__text {
  font-size: 0.9rem;
  opacity: 0.5;
}

.error-banner {
  margin-top: 1rem;
}
