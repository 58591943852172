.select-choice {
  align-items: center;
  border: 1px solid;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  padding: 12px 16px;
  position: relative;
  background-color: var(--white);
  border-color: var(--dark-grey);
  color: var(--black);
  width: -webkit-fill-available;
  width: -moz-available;
}

.select-choice:hover {
  /* Modifiche quando il componente viene cliccato */
  background-color: var(
    --white
  ); /* Sostituisci con il colore di sfondo desiderato */
  border-color: var(
    --blue
  ); /* Sostituisci con il colore del bordo desiderato */
  color: var(--blue); /* Sostituisci con il colore del testo desiderato */
}

.select-choice .sample-text {
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: normal;
  width: max-content;
}

.select-choice.unselected {
  background-color: var(--white);
  border-color: var(--orange);
}

.select-choice.hover {
  background-color: #ffffff;
  border-color: var(--blue);
}

.select-choice.selected {
  background-color: var(--blue);
  border-color: var(--blue);
}

.select-choice.unselected .sample-text {
  color: var(--dark-grey);
}

.select-choice.hover .sample-text {
  color: var(--orange);
}

.select-choice.selected .sample-text {
  color: var(--white);
}

.select-choice.clicked {
  /* Modifiche quando il componente viene cliccato */
  background-color: var(
    --blue
  ); /* Sostituisci con il colore di sfondo desiderato */
  border-color: var(
    --blue
  ); /* Sostituisci con il colore del bordo desiderato */
  color: var(--white); /* Sostituisci con il colore del testo desiderato */
  /* Aggiungi altre modifiche CSS desiderate qui */
}

.img-checkbox {
  border: 1.5px solid var(--blue);
  border-radius: 4px;
  background-color: #0946922a;
}

.img-checkbox__unchecked {
  border: 1.5px solid transparent;
}

.logo-wrapper {
  padding: 36px;
}

.logo-wrapper img {
  width: 150px !important;
  height: auto !important;
}
