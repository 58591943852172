.banner-ok  {
    align-items: flex-start;
    background-color: var(--white);
    display: flex;
    width: 100%;
    position: relative;
  }
  
  .banner-ok  .div-ok {
    align-items: center;
    flex: 1;
    background-color: var(--panna);
    border-radius: 8px;
    display: flex;
    position: relative; /* Aggiungi position: relative */
  }
  
  
  .banner-ok .text-frame {
    align-items: flex-start;
    display: flex;
    flex: 1;
    flex-direction: column;
    flex-grow: 1;
    gap: 4px;
    padding: 16px 0px 16px 16px;
    position: relative;
  }
  
  .banner-ok .headline-text {
    align-self: stretch;
    color: var(--green);
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
  }
  
  .banner-ok  .paragraph-text {
    align-self: stretch;
    color: var(--green);
    font-family: "Inter", Helvetica;
    font-size: 16px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 24px;
    position: relative;
    white-space: wrap;
  }
  