.logo {
  height: 80px;
  margin-left: 8px;
  margin-top: 14px;
  margin-bottom: 24px;
}

.menu {
  flex: 0 0 237px; /* Larghezza fissa di 250px */
  position: fixed; /* Rende il menù fisso */
  height: 100vh; /* Altezza completa della viewport */
  overflow-y: auto; /* In caso di molte voci, rende il menù scorrevole */
  z-index: 200;
}

.menu-container {
  background-color: var(--white);
  min-height: 100%;
  border-right: 1.5px solid var(--grey);
  position: relative;
}

.poweredby-text {
  width: 100%;
  text-align: center;
  font-size: 14px;
  margin-top: auto;
  color: #b3b3b3;
  margin-top: 8px;
  margin-bottom: 8px;
  font-weight: bold;
  position: absolute;
  bottom: 1.2rem;
  display: flex;
  align-items: center;
  grid-gap: 8px;
  justify-content: center;
}

.poweredby-text img {
  height: 18px;
  width: fit-content;
}
