.log-in-interno {
  align-items: center;
  background-color: #f6fdff;
  border: 1px none;
  display: flex;
  flex-direction: column;
  position: relative;
  justify-content: center; /* Center horizontally */
  align-items: center; /* Center vertically */
}

.log-in-interno .logo {
  height: 100px;
  min-width: 200px;
  object-fit: cover;
  position: relative;
}

.log-in-interno .login-screen {
  align-items: center;
  background-color: var(--white);
  border: 1px solid;
  border-color: var(--grey);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  overflow: hidden;
  padding: 36px;
  position: relative;
  width: 480px;
}

.log-in-interno .typeforms-buttons {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
}

.log-in-interno .title-container {
  align-items: center;
  align-self: stretch;
  display: flex;
  justify-content: center;
  position: relative;
}

.log-in-interno .title {
  color: var(--black);
  flex: 1;
  font-family: "Sharp Grotesk-Medium20", Helvetica;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 28.8px;
  margin-top: -1px;
  position: relative;
  text-align: center;
  white-space: nowrap;
}

.log-in-interno .div {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
}

.log-in-interno .type-form-instance {
  align-self: stretch !important;
  width: unset !important;
}

.log-in-interno .design-component-instance-node {
  min-width: 24px !important;
  width: unset !important;
}

.log-in-interno .other-typeform {
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  justify-content: space-between;
  position: relative;
}

.log-in-interno .ricordami-check {
  align-items: flex-start;
  display: flex;
  gap: 8px;
  position: relative;
  width: fit-content;
}

.log-in-interno .text-wrapper {
  color: var(--orange);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
}

.log-in-interno .text-wrapper-2 {
  color: var(--orange);
  font-family: "Inter", Helvetica;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: -1px;
  position: relative;
  text-decoration: underline;
  white-space: nowrap;
  width: fit-content;
}

.log-in-interno .buttons {
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: 16px;
  position: relative;
}

.log-in-interno .button-instance {
  align-self: stretch !important;
  justify-content: center !important;
  width: unset !important;
}

/* Responsive Styles */

@media (max-width: 768px) {
  .log-in-interno {
    padding: 20px;
  }

  .log-in-interno .login-screen {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .log-in-interno .login-screen {
    padding: 20px;
  }
}
