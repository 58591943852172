body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito Sans", sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
  font-family: "Nunito Sans", sans-serif;
}

* {
  box-sizing: border-box;
}

:root {
  --orange: #f07c01;
  --darkOrange: #d56e00;
  --black: rgba(0, 6, 36, 1);
  --blue: #094692;
  --dark-grey: rgba(151, 151, 151, 1);
  --grey: rgba(230, 230, 230, 1);
  --red: rgba(211, 20, 9, 1);
  --green: #287744;
  --white: rgba(255, 255, 255, 1);
  --secondary-blue: #3690ff;
  --light-blue: #f7f9ff;
  --panna: #fffbf1;
  --ligh-green: rgb(164, 240, 145);
}
