.container {
  display: flex; /* Usiamo Flexbox */
  height: 100vh; /* Altezza completa della viewport */
}

.content {
  flex: 1; /* Occupa tutto lo spazio rimanente */
  margin-left: 250px; /* Spazio a sinistra per evitare il menù */
  padding: 20px; /* Spazio interno al contenuto */
  /* max-width: calc(100% - 250px); */
  overflow-y: auto; /* Rende il contenuto scorrevole se necessario */
}

.badge-uomo {
  display: inline-block;
  padding: 0.25em 0.75em;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
  background-color: #007bff; /* Colore di sfondo del badge */
  color: #ffffff; /* Colore del testo del badge */
  margin-right: 0.5rem; /* Spazio a destra del badge (se si hanno più badge in fila) */
}

.badge-donna {
  display: inline-block;
  padding: 0.25em 0.75em;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
  background-color: #ed7fdb; /* Colore di sfondo del badge */
  color: #ffffff; /* Colore del testo del badge */
  margin-right: 0.5rem; /* Spazio a destra del badge (se si hanno più badge in fila) */
}

.badge-si {
  display: inline-block;
  padding: 0.25em 0.75em;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
  background-color: #1eff00; /* Colore di sfondo del badge */
  color: #ffffff; /* Colore del testo del badge */
  margin-right: 0.5rem; /* Spazio a destra del badge (se si hanno più badge in fila) */
}

.badge-no {
  display: inline-block;
  padding: 0.25em 0.75em;
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.375rem;
  background-color: #eb3d46; /* Colore di sfondo del badge */
  color: #ffffff; /* Colore del testo del badge */
  margin-right: 0.5rem; /* Spazio a destra del badge (se si hanno più badge in fila) */
}

.table-admin-button-container {
  display: flex;
  width: 100%;
  margin-top: 2%;
  margin-bottom: 3%;
  font-family: "Inter", Helvetica, sans-serif; /* Imposta il font a livello del contenitore */
  position: sticky;
  left: 0;
}

.table-admin-button-container__wrapper {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 4px;
}

.table-admin-flex-button {
  padding: 0.7rem 0.9rem;
  border: none;
  color: var(--white);
  font-size: 16px;
  cursor: pointer;
  border-radius: 8px;
  margin-right: 1%;
  font-size: 0.9rem;
  font-weight: bold;
  width: fit-content;
  transition: none;
  border: 2px solid var(--blue);
  white-space: nowrap;

  /* opzionale: per dare un effetto quando si passa sopra con il mouse */
}

.table-admin-legenda-right {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 10px;
}

.table-admin-legenda-right__color-circle {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #a0d3001a;
  border: 1px solid var(--grey);
}

.table-admin-legenda-right p {
  margin: 0;
}
.table-admin-legenda-right p:nth-of-type(1) {
  font-weight: 800;
  font-size: 14px;
  color: var(--dark-grey);
}
.table-admin-legenda-right p:nth-of-type(2) {
  color: var(--dark-grey);
  font-size: 12px;
  font-weight: 400;
}

.isSelected {
  background-color: var(--blue);
}

.isNotSelected {
  background-color: transparent;
  color: var(--dark-grey);
  border: 1.5px solid var(--grey);

  transition: background-color 0.3s;
}

.count-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  align-items: center;
}

.count-wrapper :nth-of-type(1) {
  font-weight: lighter;
}

.count-wrapper :nth-of-type(2) {
  font-weight: 800;
  margin-left: 4px;
}

.count-wrapper :nth-of-type(3) {
  font-weight: 800;
  margin-left: 4px;
}

.count-wrapper img {
  width: 20px;
  height: 20px;
  margin-left: 5px;
  cursor: pointer;
}

.disabled {
  opacity: 0.8;
  pointer-events: none;
  cursor: default;
  filter: grayscale(1);
  transform: scale(0.8);
}

.cella-seac {
  background-color: #a0d3001a;
}
